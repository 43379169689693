import ValidationErrors from '@/components/reports/config/common/ValidationErrors'

export default {
  components: {
    ValidationErrors,
    MeasureEditor: () => import('@/components/reports/config/measures/MeasureEditor'),
    ExpressionEditor: () => import('@/components/reports/config/expressions/ExpressionEditor'),
    DimensionEditor: () => import('@/components/reports/config/dimensions/DimensionEditor'),
    FilterEditor: () => import('@/components/reports/config/filters/FilterEditor'),
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    value: {
      type: Object | Array,
      default: null,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      depthColors: [
        '#1E90FF',
        'green',
        'purple',
        'orange',
        'tomato',
        '#BA55D3',
      ],
      dataTypes: [
        'money',
        'percentage',
        'number',
        'label',
        'id',
        'boolean',
        'date',
        'time',
        'datetime',
        'duration',
      ],
      timeRangeOptions: [
        {
          key: null,
          name: 'Use report default',
        },
        {
          type: 'CalendarPreset',
          name: 'Current Day',
          key: 'current_day',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Previous Day',
          key: 'previous_day',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Week to Date',
          key: 'wtd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Current Week',
          key: 'current_week',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Past 7 Days',
          key: 'trailing_7',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Month To Date',
          key: 'mtd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Current Month',
          key: 'current_month',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Period To Date',
          key: 'ptd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'Quarter to Date',
          key: 'qtd',
          include_today: false,
        },
        {
          type: 'CalendarPreset',
          name: 'year to date',
          key: 'ytd',
          include_today: false,
        },
      ],
    }
  },
  methods: {
    colorByDepth () {
      return this.depthColors[this.depth % this.depthColors.length]
    },
    colorByPrevDepth () {
      return this.depthColors[(this.depth - 1) % this.depthColors.length]
    },

    refreshOptions () {
      this.$store.dispatch('app/reportsConfig/refreshOptions')
    },
  },
  computed: {
    errors () {
      return this.vModel.errors || []
    },
    reportsConfigPath () {
      const companyId = this.$store.getters['companyId']
      return `/company/${companyId}/reports/config`
    },
    companyMeasureOptions () {
      return this.$store.getters['app/reportsConfig/companyMeasureOptions']
    },
    companyDimensionOptions () {
      return this.$store.getters['app/reportsConfig/companyDimensionOptions']
    },
    companyExpressionOptions () {
      return this.$store.getters['app/reportsConfig/companyExpressionOptions']
    },
    companyFilterOptions () {
      return this.$store.getters['app/reportsConfig/companyFilterOptions']
    },
    adminCompanyExpressionOptions () {
      const expressions = { ...this.$store.getters['expressions/companyExpressions'], }
      Object.keys(expressions).forEach(
        key => {
          if (expressions[key].generator_id != null) {
            delete expressions[key]
          }
        }
      )
      return expressions
    },
    generatedCompanyExpressionOptions () {
      const expressions = this.$store.getters['expressions/companyExpressions']
      const generatedExpressions = {}
      Object.values(expressions).forEach(
        expression => {
          if (expression.generator_id != null) {
            generatedExpressions[expression.generator_id] = expression
          }
        }
      )
      return generatedExpressions
    },
    vModel: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    subObjectBinds () {
      return {
        depth: this.depth + 1,
        mode: this.mode,
      }
    },
  },
}

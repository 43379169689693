<template>
  <div class="email-send-history">
    <div class="header">
      <h1>
        Send History
      </h1>
      <label>
        Source:
        <MultiSelect
          :options="sourceOptions"
          v-model="sourceSelection"/>
      </label>
      <label>
        Status:
        <MultiSelect
          :options="statusOptions"
          v-model="statusSelection"/>
      </label>
      <a-button
        class="ml-5"
        @click="refreshData">
        Refresh Attempts
      </a-button>
    </div>
    <table class="body">
      <tr>
        <th class="event-field">
          Created At
        </th>
        <th class="event-field">
          Email Report
        </th>
        <th class="event-field">
          Run Date
        </th>
        <th class="event-field">
          Source
        </th>
        <th class="event-field">
          Status
        </th>
        <th class="event-field">
          Recipients
        </th>
        <th />
      </tr>

      <tr v-for="sendEvent in attemptData"
          :key="sendEvent.id"
          class="email-attempt">
        <td class="event-field">
          {{ sendEvent.createdAt }}
        </td>
        <td class="event-field">
          {{ sendEvent.emailReportDisplayName }}
        </td>
        <td class="event-field">
          {{ sendEvent.reportParams.date }}
        </td>
        <td class="event-field">
          {{ sendEvent.sourceType }}
        </td>
        <td class="event-field">
          {{ sendEvent.status }}
        </td>
        <td class="event-field">
          <template v-if="sendEvent.reportParams.useRealRecipients">
            Default Recipients
          </template>
          <template v-else>
            {{ sendEvent.reportParams.recipientOverride }}
          </template>
        </td>
        <td>
          <a-button
            class="ml-5"
            @click="() => rerunSingle(sendEvent)">
            Rerun Attempt
          </a-button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

  import MultiSelect from '@/components/ui/multiselect'

  export default {
    name: 'EmailSendHistory',
    components: {
      MultiSelect,
    },
    data () {
      return {
        attemptData: [],
        autoRefresh: false,
        statusOptions: [
          {
            id: 'RUNNING',
            name: 'Running',
          },
          {
            id: 'FAILED',
            name: 'Failed',
          },
          {
            id: 'COMPLETED',
            name: 'Completed',
          },
        ],
        statusSelection: [
          {
            id: 'RUNNING',
            name: 'Running',
          },
          {
            id: 'FAILED',
            name: 'Failed',
          },
          {
            id: 'COMPLETED',
            name: 'Completed',
          },
        ],
        sourceOptions: [
          {
            id: 'MANUAL',
            name: 'Manual',
          },
          {
            id: 'SCHEDULED',
            name: 'Scheduled',
          },
        ],
        sourceSelection: [
          {
            id: 'MANUAL',
            name: 'Manual',
          },
          {
            id: 'SCHEDULED',
            name: 'Scheduled',
          },
        ],
      }
    },
    methods: {
      async refreshData () {
        this.attemptData = await this.$apis.emailReports.getEmailHistory(
          this.statusSelection,
          this.sourceSelection
        )
        if (this.autoRefresh) {
          await new Promise(r => setTimeout(r, 1000))
          this.refreshData()
        }
      },
      rerunSingle (sendEvent) {
        if (confirm(`Re-Send Email: ${sendEvent.emailReportDisplayName}?`)) {
          this.rerunReport(
            sendEvent.emailReportId,
            sendEvent.id
          )
        }
      },
      async rerunReport (reportId, eventId) {
        this.$apis.emailReports.rerunAttempt(
          reportId,
          eventId
        )
      },
    },
    mounted () {
      this.refreshData()
    },
    beforeDestroy () {
      this.autoRefresh = false
    },
  }

</script>

<style lang="scss" scoped>

.email-send-history {
  overflow-y: auto;
  position: relative;
  padding-bottom: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;

  > h1 {
    flex-grow: 1;
  }

  > * {
    margin: 16px;
  }
}

label {
  display: flex;
  align-items: center;
  font-size: 14px;

  > * {
    margin: 8px;
  }
}

.event-field {
  padding: 12px;
  font-size: 12px;
}

</style>

<template>
  <div class="filter-list-editor">
    <div
      v-for="filter, idx in vModel"
      :key="idx"
      :class="{'error-border': filter.errors}"
      class="list-item">
      <FilterConfigEditor
        :value="filter"
        @input="updateFilter(idx, $event)" />
      <button
        class="delete"
        @click="deleteFilter(idx)" />
    </div>
    <b-button @click.native="addFilter">
      Add Filter
    </b-button>
  </div>
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import FilterConfigEditor from '@/components/reports/config/modules/common/FilterConfigEditor'

  export default {
    name: 'FilterConfigListEditor',
    components: {
      FilterConfigEditor,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    methods: {
      addFilter () {
        this.vModel = [...this.vModel, {}, ]
      },
      updateFilter (idx, filter) {
        this.vModel.splice(idx, 1, filter)
        // Hack to force updates in vue because it seems like
        // splice isn't recognized correctly but its prob something
        // wrong with filter editors tbh
        this.vModel = [ ...this.vModel, ]
      },
      deleteFilter (idx) {
        this.vModel.splice(idx, 1)
      },
    },
  }

</script>

<style lang="scss" scoped>

.filter-list-editor {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.list-item {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  border: $border-1;
  border-radius: 10px;

  .delete {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.filter-config-editor {
  flex-grow: 1;
  flex-direction: row;
}

</style>
